import React from "react";
import styles from "./ParallaxImg.module.css";
function ParallaxImg() {
  return (
    <div className={styles.parallax}>
    </div>
  );
}

export default ParallaxImg;
